import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Mental pain is less dramatic than physical pain, but it is more common and
also more hard to bear. The frequent attempt to conceal mental pain increases
the burden: it is easier to say “My tooth is aching” than to say “My heart is
broken.” - C.S. Lewis`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Pain holds crucial information about what deeply matters to us. And yet, we
are practically encouraged to dismiss, avoid, "get over," and invalidate
anything that is remotely uncomfortable or difficult. What sense does that
make? - Dennis Tirch`}</p>
    </blockquote>
    <p>{`Listening to pain early and often allows us to handle it while it is still
manageable. Lean in and deal with it, don’t run.`}</p>
    <p>{`Pain is a signal that something is wrong. Ignoring that signal will only create
more damage and consequently more pain. Pain should be the trigger for a habit
to reflect and view the situation objectively. What is causing me pain? If we
view pain as our emotional systems check engine light we can use it as an
opportunity to explore and better understand ourselves.`}</p>
    <p>{`To just muscle through pain when there is work to be done about it, is a fool's
errand. The pain only propagates and grows, becomes unmanageable. If all else
fails... Always remember that "where there is life, there is hope". If we are
breathing, there is still hope. We are far more resilient than any of us ever
thought possible.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Afterwards I learned, that the best way to manage some kinds of painful
thoughts, is to dare them to do their worst; to let them lie and gnaw at your
heart till they are tired; and you find you still have a residue of life they
cannot kill. - George MacDonald`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      